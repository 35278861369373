import CmsBlock from "app/layout/cmsBlock";
import React from "react";
import classes from "./lightedMirrorsPage.module.css";
import SeoHelmet from "app/layout/seoHelmet";
export const LightedMirrorsPage = () => {
  return (
    <div className={classes.root}>
      <SeoHelmet
        title={"Lighted Mirrors"}
        url={"/lighted-mirrors"}
        description={"Lighted Mirrors"}
      />
      <CmsBlock id="lighted-mirrors" />
    </div>
  );
};
