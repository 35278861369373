import Homepage from "app/pages/home";
import Typography from "app/pages/typography";
import Login from "app/pages/customer/login";
import ProductDetailsPage from "app/pages/product-details-page/product-details-page";
import Contact from "app/pages/contact";
import { SiteMapContainer as SiteMap } from "app/pages/static/site-map/site-map-query-container";
import { AboutUsContainer as AboutUs } from "app/pages/static/about-us/about-us-query-container";
import RegisterThankYou from "app/pages/static/registration";
import ForgotPassword from "app/pages/static/forgot-password";
import QuoteRequest from "app/layout/quoteRequest";
import { QuoteRequestThanksContainer as QuoteRequestThankYou } from "app/layout/quoteRequestThankYou/quote-request-thanks-query-container";
import PortfolioGallery from "app/pages/static/portfolio-gallery";
import SearchStateContainer from "app/pages/catalog/search-results/state-container";
import FindRep from "app/pages/static/find-a-rep";
import FaqPage from "app/pages/static/faq-page";
import ResetPasswordPage from "app/pages/static/resetPasswordPage";
import TermsConditions from "app/pages/static/terms-conditions";
import PrivacyPolicy from "app/pages/static/privacy-policy";
import TechnicalInformation from "app/pages/static/technical-information";
import CatalogPage from "app/pages/static/catalogs";
import { LightedMirrorsPage } from "app/pages/static/lighted-mirrors/LightedMirrosPage";
import BabaCompliance from "app/pages/static/baba-compliance/BabaCompliance";

export default {
  home: {
    component: Homepage,
    exact: false,
  },
  "reset-password": {
    component: ResetPasswordPage,
    exact: false,
  },

  "rep-location": {
    component: FindRep,
    exact: false,
  },
  login: {
    component: Login,
    exact: false,
  },
  typography: {
    component: Typography,
    exact: false,
  },
  "products-static": {
    component: ProductDetailsPage,
    exact: false,
  },
  "contact-us": {
    component: Contact,
    exact: false,
  },
  registration: {
    component: RegisterThankYou,
    exact: false,
  },
  "forgot-password": {
    component: ForgotPassword,
    exact: false,
  },
  faq: {
    component: FaqPage,
    exact: false,
  },

  "quote-request": {
    component: QuoteRequest,
    exact: false,
  },

  "quote-request-thank-you": {
    component: QuoteRequestThankYou,
    exact: false,
  },
  "site-map": {
    component: SiteMap,
    exact: false,
  },
  "about-us": {
    component: AboutUs,
    exact: false,
  },
  portfolio: {
    component: PortfolioGallery,
    exact: false,
  },
  "search-results": {
    component: SearchStateContainer,
    exact: false,
  },
  "terms-and-conditions": {
    component: TermsConditions,
    exact: false,
  },
  "privacy-policy": {
    component: PrivacyPolicy,
    exact: false,
  },
  "technical-information": {
    component: TechnicalInformation,
    exact: false,
  },
  brochures: {
    component: CatalogPage,
    exact: false,
  },
  "lighting-fixtures/lighted-mirrors/": {
    component: LightedMirrorsPage,
    exact: true,
  },
  "baba-compliance": {
    component: BabaCompliance,
    exact: false,
  },
};

export { default as ErrorPage } from "app/pages/error";

export { default as TemporaryPage } from "app/pages/temporary";

export const DefaultPage = Homepage;
