import gql from "graphql-tag";
export const PRODUCT = gql`
  query Product($filters: ProductAttributeFilterInput!) {
    products(filter: $filters) {
      total_count
      items {
        ... on GroupedProduct {
          id
          name
          sku
          items {
            qty
            position
            product {
              id
              sku
              name
              stock_status
              __typename
              url_key
            }
          }
        }
        id
        name
        sku
        url_key
        __typename
        catalog_numbers
        max_delivered_lumens
        max_power_consumption
        PROD: custom_item_verenia_name
        PRODLINE: custom_item_verenia_prodline
        color_temp
        cri
        dimming_compatibility
        input_voltage
        custom_item_option_1
        custom_item_option_2
        custom_item_option_3
        custom_item_option_4
        custom_item_option_5
        custom_item_option_6
        custom_item_option_7
        custom_item_option_8
        custom_item_option_9
        custom_item_option_10
        lifestyle_image
        lifestyle_image2
        lifestyle_image3
        custom_item_pdf_1
        custom_item_pdf_2
        custitem_pdf_attachment_1
        custom_item_ies_file
        custom_item_image_link
        custom_item_image_link_2
        custom_item_image_link_3
        custom_item_image_link_4
        custom_item_image_link_5
        sizes_dimensions
        custom_item_prima_web_drawing
        stock_status
        meta_description
        meta_title
        prima_spec_sheet
        item_solid_model
        install_instruction
        categories {
          id
          name
          url_key
          path
        }
        description {
          html
        }
        ... on CustomizableProductInterface {
          options {
            title
            required
            sort_order
            option_id
            ... on CustomizableDropDownOption {
              option_id
              title
              value {
                title
                option_type_id
                price
                price_type
                sku
              }
            }
          }
        }
        carousel_images {
          url
          label
        }
        upsell_products {
          id
          name
          sku
          url_key
          image {
            label
            url
            disabled
          }
          small_image {
            label
            url
            disabled
          }
          thumbnail {
            url
            label
            disabled
          }
        }

        media_gallery_entries {
          id
          file
          media_type
          content {
            base64_encoded_data
            name
            type
          }
          disabled
        }

        image {
          label
          url
        }
        small_image {
          label
          url
        }

        related_products {
          id
          name
          sku
          url_key
          small_image {
            label
            url
          }
          image {
            label
            url
          }
        }
      }
    }
  }
`;

export const CUSTOMER_PRODUCT = gql`
  query CustomerProduct($filters: ProductAttributeFilterInput!) {
    products(filter: $filters) {
      total_count
      items {
        id
        __typename
        name
        max_delivered_lumens
        max_power_consumption
        color_temp
        cri
        dimming_compatibility
        input_voltage
        PROD: custom_item_verenia_name
        PRODLINE: custom_item_verenia_prodline
        custom_item_option_1
        custom_item_option_2
        custom_item_option_3
        custom_item_option_4
        custom_item_option_5
        custom_item_option_6
        custom_item_option_7
        custom_item_option_8
        custom_item_option_9
        custom_item_option_10
        lifestyle_image
        lifestyle_image2
        lifestyle_image3
        custom_item_pdf_1
        custom_item_pdf_2
        custitem_pdf_attachment_1
        custom_item_ies_file
        custom_item_image_link
        custom_item_image_link_2
        custom_item_image_link_3
        custom_item_image_link_4
        custom_item_image_link_5
        sizes_dimensions
        custom_item_prima_web_drawing
        prima_spec_sheet
        item_solid_model
        install_instruction
        carousel_images {
          url
          label
        }
        related_products {
          name
          sku
          image {
            url
          }
          price_range {
            maximum_price {
              regular_price {
                value
                currency
              }
            }
          }
        }
        categories {
          id
          name
          url_key
          path
          path_in_store
        }
        stock_status
        description {
          html
        }
        sku
        url_key
        media_gallery_entries {
          id
          file
          media_type
          content {
            base64_encoded_data
            name
            type
          }
          disabled
        }
        image {
          label
          url
        }
        small_image {
          label
          url
        }

        price_range {
          minimum_price {
            discount {
              amount_off
              percent_off
            }
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
        }
        tier_prices {
          customer_group_id
          percentage_value
          qty
          value
          website_id
        }
        small_image {
          label
          url
        }
        image {
          label
          url
        }
        ... on GroupedProduct {
          id
          name
          sku
          items {
            qty
            position
            product {
              id
              sku
              name
              stock_status
              url_key
            }
          }
        }
      }
    }
  }
`;

export const FETCH_PRODUCT = gql`
  query FETCH_PRODUCT_BY_SKU($sku: String) {
    products(filter: { sku: { eq: $sku } }) {
      items {
        id
        lifestyle_content
        attribute_set_id
        baba_eligible
        uid
        name
        sku
        url_key
        __typename
        catalog_numbers
        max_delivered_lumens
        max_power_consumption
        PROD: custom_item_verenia_name
        PRODLINE: custom_item_verenia_prodline
        color_temp
        cri
        dimming_compatibility
        input_voltage
        ark_lamp_height
        ark_lamp_width
        custom_item_option_1
        custom_item_option_2
        custom_item_option_3
        custom_item_option_4
        custom_item_option_5
        custom_item_option_6
        custom_item_option_7
        custom_item_option_8
        custom_item_option_9
        custom_item_option_10
        lifestyle_image
        lifestyle_image2
        lifestyle_image3
        custom_item_pdf_1
        custom_item_pdf_2
        custitem_pdf_attachment_1
        custom_item_ies_file
        custitem_color_picker
        custitem_closeout
        custom_item_image_link
        custom_item_image_link_2
        custom_item_image_link_3
        custom_item_image_link_4
        custom_item_image_link_5
        sizes_dimensions
        custom_item_prima_web_drawing
        stock_status
        meta_description
        meta_title
        ark_quantityavailable
        ark_web_available_1
        ark_web_available_2
        ark_web_available_4
        ark_web_available_6
        ark_web_available_14
        ark_sales_description
        ark_min_order_qty
        ark_turnaround_code
        ark_min_order_qty
        ark_turnaround_code
        ark_web_turnaround_code
        ark_min_order_qty
        ark_sales_description
        ark_custitem_prima_sales_desc
        ark_main_color
        ark_shade_dimensions
        ark_material_type
        ark_shade_material
        ark_primary_finish
        ark_power_cord
        ark_voltage
        ark_bulb_wattage
        ark_base
        ark_custitem_ul_rating
        ark_closeout
        ark_closeout_price
        basics_flag
        ark_made_in_usa
        ark_lamp_type
        prima_spec_sheet
        item_solid_model
        install_instruction
        price_range {
          minimum_price {
            regular_price {
              value
              currency
            }
          }
        }
        price_tiers {
          discount {
            amount_off
            percent_off
          }
          final_price {
            value
            currency
          }
          quantity
        }
        categories {
          id
          name
          url_key
          path
        }
        description {
          html
        }
        ... on CustomizableProductInterface {
          options {
            title
            required
            sort_order
            option_id
            ... on CustomizableDropDownOption {
              option_id
              title
              value {
                title
                option_type_id
                price
                price_type
                sku
              }
            }
          }
        }
        carousel_images {
          url
          label
        }
        upsell_products {
          id
          name
          sku
          url_key
          image {
            label
            url
            disabled
          }
          small_image {
            label
            url
            disabled
          }
          thumbnail {
            url
            label
            disabled
          }
        }
        media_gallery_entries {
          id
          file
          media_type
          content {
            base64_encoded_data
            name
            type
          }
          disabled
        }
        image {
          label
          url
        }
        small_image {
          label
          url
        }
        related_products {
          id
          name
          sku
          url_key
          small_image {
            label
            url
          }
          image {
            label
            url
          }
        }
        ... on GroupedProduct {
          id
          name
          sku
          items {
            qty
            position
            product {
              prima_spec_sheet
              item_solid_model
              install_instruction
              ... on CustomizableProductInterface {
                options {
                  title
                  required
                  sort_order
                  option_id
                  ... on CustomizableDropDownOption {
                    option_id
                    title
                    value {
                      title
                      option_type_id
                      price
                      price_type
                      sku
                    }
                  }
                }
              }
              attribute_set_id
              ark_quantityavailable
              ark_web_available_1
              ark_web_available_2
              ark_web_available_4
              ark_web_available_6
              ark_web_available_14
              ark_sales_description
              ark_turnaround_code
              ark_min_order_qty
              ark_turnaround_code
              ark_web_turnaround_code
              ark_min_order_qty
              ark_custitem_prima_sales_desc
              ark_main_color
              ark_shade_dimensions
              ark_material_type
              ark_shade_material
              ark_primary_finish
              ark_power_cord
              ark_voltage
              ark_bulb_wattage
              ark_base
              ark_custitem_ul_rating
              ark_sales_description
              ark_min_order_qty
              ark_turnaround_code
              ark_closeout
              ark_closeout_price
              basics_flag
              ark_made_in_usa
              ark_lamp_type
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                }
              }
              price_tiers {
                discount {
                  amount_off
                  percent_off
                }
                final_price {
                  value
                  currency
                }
                quantity
              }
              id
              sku
              name
              stock_status
              __typename
              url_key

              media_gallery_entries {
                id
                file
                media_type
                content {
                  base64_encoded_data
                  name
                  type
                }
                disabled
              }
              image {
                label
                url
              }
              small_image {
                label
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const SKU_EXCEPTION_QS = gql`
  query {
    getSkuExceptions {
      name
      rule
      price
    }
  }
`;
