import { MDBBox } from "mdbreact";
import React from "react";

const CatalogItem = ({ catalogs, item, index, change, setChange }) => {
  return (
    change === index && (
      <MDBBox>
        <iframe
          className="catalogs-frame"
          title="catalogsFrame"
          src={item?.fliphtml5}
          frameBorder="0"
          allowFullScreen={true}
          webkitallowfullscreen={true}
          mozallowfullscreen={true}
        />
      </MDBBox>
    )
  );
};

export default CatalogItem;
