import React, { useState } from "react";
import CmsBlock from "app/layout/cmsBlock";
import "./_baba-compliance.scss";
import SeoHelmet from "app/layout/seoHelmet";
const BabaCompliance = () => {
  const [tab, setTab] = useState("taa-complience");

  const babaCompliance = [
    { label: "TAA (Trade Agreement Act Compliant)", value: "taa-complience" },
    { label: "BAA (Buy American Act Compliant)", value: "baa-complience" },
    { label: "BABA (Build America Buy America)", value: "baba-complience" },
  ];

  return (
    <>
      <SeoHelmet title={"TAA/BAA/BABA Compliance"} url={"/baba-compliance"} />
      <div className="baba-banner">
        <CmsBlock id="baba-complience-banner" />
      </div>
      <div className="container root">
        <h1 className="header">TAA/BAA/BABA Compliance</h1>
        <div className="tabs">
          {babaCompliance.map((c) => (
            <div
              className={`tab ${tab === c.value && "selectedTab"}`}
              onClick={() => setTab(c.value)}
              key={c.value}
            >
              {c.label}
            </div>
          ))}
        </div>
        <CmsBlock id={tab} />
        <CmsBlock id={"baba-complience-description"} />
      </div>
    </>
  );
};
export default BabaCompliance;
